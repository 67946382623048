import router from "@/router";
import ResultService from "@/services/partners/Result.service";
import {
  DEFAULT_PAGINATION_ACTIVITY_LOGS,
  DEFAULT_PAGINATION_DASHBOARD_INFO,
  ResultState,
} from ".";
import {
  __GetActivityLogs,
  __GetCampaignCondition,
  __GetCampaignDropdown,
  __GetCampaignInfo,
  __GetDashboardChart,
  __GetDashboardInfo,
  __GetDashboardSummary,
  __GetExcelResult,
  __ViewCampaignResult,
} from "./action-dto";

/* library toast */
import "vue3-toastify/dist/index.css";

/* Service */
import ToastService from "@/services/Toast.service";

export default {
  VIEW_CAMPAIGN_RESULT(state: ResultState, payload: __ViewCampaignResult) {
    state.campaign_code_selected = payload.campaignCode;
    router.replace({ name: "Results" });
  },

  GET_CAMPAIGN_DROPDOWN(state: ResultState, payload: __GetCampaignDropdown) {
    state.loading_campaign_dropdown = true;
    state.error_campaign_dropdown = null;

    ResultService.getCampaignDropdown(payload)
      .then((res) => {
        state.error_campaign_dropdown = null;
        state.campaign_dropdown = res.data;
      })
      .catch((err) => {
        state.error_campaign_dropdown = "พบข้อผิดพลาด";
        state.campaign_dropdown = [];
      })
      .finally(() => {
        state.loading_campaign_dropdown = false;
      });
  },

  GET_CAMPAIGN_INFO(state: ResultState, payload: __GetCampaignInfo) {
    state.loading_campaign_info = true;
    state.error_campaign_info = null;

    ResultService.getCampaignInfo(payload)
      .then((res) => {
        state.error_campaign_info = null;
        state.campaign_info = res.data;
      })
      .catch((err) => {
        state.error_campaign_info = "พบข้อผิดพลาด";
        state.campaign_info = null;
      })
      .finally(() => {
        state.loading_campaign_info = false;
      });
  },

  GET_CAMPAIGN_CONDITION(state: ResultState, payload: __GetCampaignCondition) {
    state.loading_campaign_condition = true;
    state.error_campaign_condition = null;

    ResultService.getCampaignCondition(payload)
      .then((res) => {
        state.error_campaign_condition = null;
        state.campaign_condition = res.data;
      })
      .catch((err) => {
        state.error_campaign_condition = "พบข้อผิดพลาด";
        state.campaign_condition = null;
      })
      .finally(() => {
        state.loading_campaign_condition = false;
      });
  },

  GET_DASHBOARD_SUMMARY(state: ResultState, payload: __GetDashboardSummary) {
    state.loading_dashboard_summary = true;
    state.error_dashboard_summary = null;

    ResultService.getDashboardSummary(payload)
      .then((res) => {
        state.error_dashboard_summary = null;
        state.dashboard_summary = res.data;
      })
      .catch((err) => {
        state.error_dashboard_summary = "พบข้อผิดพลาด";
        state.dashboard_summary = null;
      })
      .finally(() => {
        state.loading_dashboard_summary = false;
      });
  },

  GET_DASHBOARD_INFO(state: ResultState, payload: __GetDashboardInfo) {
    state.loading_dashboard_info = true;
    state.error_dashboard_info = null;

    ResultService.getDashboardInfo(payload)
      .then((res) => {
        state.error_dashboard_info = null;
        state.dashboard_info = res.data;
        state.pagination_dashboard_info =
          res.pagination || state.pagination_dashboard_info;
      })
      .catch((err) => {
        state.error_dashboard_info = "พบข้อผิดพลาด";
        state.dashboard_info = [];
        state.pagination_dashboard_info = DEFAULT_PAGINATION_DASHBOARD_INFO;
      })
      .finally(() => {
        state.loading_dashboard_info = false;
        // state.error_dashboard_info = "พบข้อผิดพลาด";
      });
  },

  GET_DASHBOARD_CHART(state: ResultState, payload: __GetDashboardChart) {
    state.loading_dashboard_chart = true;
    state.error_dashboard_chart = null;

    ResultService.getDashboardChart(payload)
      .then((res) => {
        state.error_dashboard_chart = null;
        state.dashboard_chart = res.data;
      })
      .catch((err) => {
        state.error_dashboard_chart = "พบข้อผิดพลาด";
        state.dashboard_chart = [];
      })
      .finally(() => {
        state.loading_dashboard_chart = false;
      });
  },

  GET_ACTIVITY_LOGS(state: ResultState, payload: __GetActivityLogs) {
    state.loading_activity_logs = true;
    state.error_activity_logs = null;

    ResultService.getActivityLogs(payload)
      .then((res) => {
        state.error_activity_logs = null;
        state.activity_logs = res.data;
        state.pagination_activity_logs =
          res.pagination || state.pagination_activity_logs;
      })
      .catch((err) => {
        state.error_activity_logs = "พบข้อผิดพลาด";
        state.activity_logs = [];
        state.pagination_activity_logs = DEFAULT_PAGINATION_ACTIVITY_LOGS;
      })
      .finally(() => {
        state.loading_activity_logs = false;
      });
  },

  RESET_CAMPAIGN(state: ResultState) {
    state.campaign_dropdown = [];
    state.loading_campaign_dropdown = false;
    state.error_campaign_dropdown = null;

    state.campaign_code_selected = null;

    state.campaign_info = null;
    state.loading_campaign_info = false;
    state.error_campaign_info = null;

    state.campaign_condition = null;
    state.loading_campaign_condition = false;
    state.error_campaign_condition = null;

    state.dashboard_summary = null;
    state.loading_dashboard_summary = false;
    state.error_dashboard_summary = null;

    state.dashboard_info = [];
    state.loading_dashboard_info = false;
    state.error_dashboard_info = null;
    state.pagination_dashboard_info = DEFAULT_PAGINATION_DASHBOARD_INFO;

    state.activity_logs = [];
    state.loading_activity_logs = false;
    state.error_activity_logs = null;
    state.pagination_activity_logs = DEFAULT_PAGINATION_ACTIVITY_LOGS;
  },

  GET_EXCEL_DASHBOARD(state: ResultState, payload: __GetExcelResult) {
    ResultService.getExcelDashboard(payload)
      .then((res) => {
        const data = res.data as any; // as any for excel
        const blob = new Blob([data], { type: res.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);
        const fileName =
          "Affiliate_result_export_as_of_" +
          payload.startDate +
          "-" +
          payload.endDate +
          ".xlsx";
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        return ToastService.showWarning("No Data!");
      });
  },

  GET_EXCEL_ACTIVITY_LOG(state: ResultState, payload: __GetExcelResult) {
    ResultService.getExcelActivityLog(payload)
      .then((res) => {
        const data = res.data as any; // as any for excel
        const blob = new Blob([data], { type: res.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);
        const fileName =
          "Affiliate_result_export_as_of_" +
          payload.startDate +
          "-" +
          payload.endDate +
          ".xlsx";
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        return ToastService.showWarning("No Data!");
      });
  },
};
