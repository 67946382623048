
import { defineComponent } from "vue";

/* component */
import VButtonGroup from "@/components/shared/button/ButtonGroup.vue";
import InputTypeDate from "@/components/shared/input/InputDate.vue";
import VButton from "@/components/shared/button/VButton.vue";
import dayjs from 'dayjs'

export default defineComponent({
  components: {
    VButtonGroup,
    InputTypeDate,
    VButton,
  },
  computed: {
    combinedDate(): string {
      return (
        this.$dayjs(this.searchConfirm.startDate).format("DD/MM/YYYY") +
        " - " +
        this.$dayjs(this.searchConfirm.endDate).format("DD/MM/YYYY")
      );
    },
  },
  data() {
    return {
      dates: [] as string[],
      isShow: false as boolean,
      searchConfirm: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
      value: "",
      dateNow: this.$dayjs().format("YYYY-MM-DD"),
    };
  },
  watch: {},
  props: {
    label: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    widthContent: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "80px",
    },
    disable: {
      type: Boolean,
      default: true,
    },
    startDate: {
      type: String,
      default: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
    },
    endDate: {
      type: String,
      default: dayjs().format("YYYY-MM-DD"),
    },
  },
  methods: {
    updateDates(dateStart, dateEnd) {
      this.dates = [dateStart, dateEnd];
    },
    onThisEvent(e1, e2) {
      this.searchConfirm = {
        ...this.searchConfirm,
        startDate: e1,
        endDate: e2,
      };
    },
    /* Search From Date to Date */
    onSearch() {
      const from = {
        startDate: this.searchConfirm.startDate,
        endDate: this.searchConfirm.endDate,
      };
      this.$emit("search", from);
      this.isShow = false;
    },
  },
});
