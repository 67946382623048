
import ADD_COMMA from "@/assets/utils/add-comma.util";
import Card from "@/components/shared/card/Card.vue";
import CardInfoComponents from "@/components/shared/card/CardInfoComponents.vue";
import CardItemComponents from "@/components/shared/card/CardItemComponents.vue";
import Pagination from "@/components/shared/pagination/Pagination.vue";
import { defineComponent } from "vue";

/* components */
import VButton from "@/components/shared/button/VButton.vue";
import VTab from "@/components/shared/tab/VTab.vue";
import ICampaignDropdown from "@/types/core/campaign-dropdown.type";

/* view */
import ResultDropdown from "@/components/view/result/ResultDropdown.vue";

import {
  __GetActivityLogs,
  __GetCampaignCondition,
  __GetCampaignDropdown,
  __GetCampaignInfo,
  __GetDashboardChart,
  __GetDashboardInfo,
  __GetDashboardSummary,
} from "@/stores/result/action-dto";
import IPagination from "@/types/api/pagination.type";
import IActivityLog from "@/types/core/activity-log.type";
import ICampaignInfo from "@/types/core/campaign-info.type";
import IDashboardSummary from "@/types/core/campaign-summary.type";
import IDashboardInfo from "@/types/core/dashboard-info.type";
import TGoalCondition from "@/types/core/goal-condition.type";
import IUser from "@/types/core/user.type";

/* components */
import CCondition from "@/components/shared/card/campaignInfo/CCondition.vue";
import CInfo from "@/components/shared/card/campaignInfo/CInfo.vue";
import ChartComponent, { ChartConfig } from "@/components/shared/chart/Chart.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import VLoading from "@/components/shared/loading/v-loading/VLoading.vue";
import router from "@/router";
import IDashboardChart from "@/types/core/dashboard-chart.type";
import InputCheckBox from "@/components/shared/input/inputCheckBox.vue";

/* Type of data */
type CampaignInfoForm = Omit<__GetCampaignInfo, "campaignCode"> & {
  campaignCode: string | null;
};
type CampaignConditionForm = Omit<__GetCampaignCondition, "campaignCode"> & {
  campaignCode: string | null;
};
type DashboardSummaryForm = Omit<__GetDashboardSummary, "campaignCode"> & {
  campaignCode: string | null;
};
type DashboardInfoForm = Omit<__GetDashboardInfo, "campaignCode"> & {
  campaignCode: string | null;
};
type DashboardChartForm = Omit<__GetDashboardChart, "campaignCode"> & {
  campaignCode: string | null;
};
type ActivityLogsForm = Omit<__GetActivityLogs, "campaignCode"> & {
  campaignCode: string | null;
};
type DateSort = "DAY" | "MONTH" | "YEAR";

export default defineComponent({
  name: "ResultsView",
  /* components */
  components: {
    Card,
    CardInfoComponents,
    CardItemComponents,
    ResultDropdown,
    Pagination,
    VTab,
    VButton,
    ChartComponent,
    DatePicker,
    CCondition,
    VLoading,
    CInfo,
    InputCheckBox,
  },
  /* data */
  data() {
    return {
      snackbar: false,
      campaignSelected: null as ICampaignDropdown | null,
      campaignInfoForm: {
        campaignCode: null,
      } as CampaignInfoForm,
      campaignConditionForm: {
        campaignCode: null,
      } as CampaignConditionForm,
      dashboardSummaryForm: {
        campaignCode: null,
        startDate: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
        endDate: this.$dayjs().format('YYYY-MM-DD'),
        showOnlyDateWithData: false,
      } as DashboardSummaryForm,
      dashboardChartForm: {
        campaignCode: null,
        startDate: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
        endDate: this.$dayjs().format("YYYY-MM-DD"),
        showOnlyDateWithData: false,
      } as DashboardChartForm,
      dashboardInfoForm: {
        campaignCode: null,
        startDate: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
        endDate: this.$dayjs().format("YYYY-MM-DD"),
        sortBy: null,
        sortDictionary: null,
        pagination: {
          page: 1,
          length: 10,
        },
        showOnlyDateWithData: false,
      } as DashboardInfoForm,
      activityLogsForm: {
        campaignCode: null,
        startDate: this.$dayjs().subtract(1, "month").format("YYYY-MM-DD"),
        endDate: this.$dayjs().format("YYYY-MM-DD"),
        sortBy: null,
        sortDictionary: null,
        pagination: {
          page: 1,
          length: 10,
        },
      } as ActivityLogsForm,
      dateFilter: {
        startDate: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
        endDate: this.$dayjs().format("YYYY-MM-DD"),
      },
      debounceTime: 500,
      campaignInfoDebounceTime: null as any,
      campaignConditionDebounceTime: null as any,
      dashboardSummaryDebounceTime: null as any,
      dashboardChartDebounceTime: null as any,
      dashboardInfoDebounceTime: null as any,
      activityLogsDebounceTime: null as any,
      tabActive: 1 as number,
      chartConfig: {
        data: [],
        date_format: "YYYY-MM-DD",
        is_show_bar: true,
        is_show_goal_line: true,
        is_show_register_line: true,
      } as ChartConfig,
      isMobile: false as boolean,
      showOnlyDateWithData: false as boolean,

      /* Tab */
      tabResult: [
        {
          id: 1,
          name: "Dashboard",
        },
        {
          id: 2,
          name: "Activity Log",
        },
      ],
      date: {
        dateSort: "DAY" as DateSort,
      },

      /* chart */
      series: [
        {
          name: "Reward",
          type: "column",
          data: [] as number[],
        },
        {
          name: "Register",
          type: "line",
          data: [] as number[],
        },
        {
          name: "Goal",
          type: "line",
          data: [] as number[],
        },
      ],
      isWaitChart: false,
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 1.5, 1.5],
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            opposite: false,
            decimalsInFloat: Number,
          },
          {
            opposite: true,
            decimalsInFloat: Number,
          },
        ],
        colors: ["#3297FF", "#6C6DF2", "#3DC0CB"] /* reward register goal */,
        /* Config Tools */
        legend: {
          show: false,
        },
      },
      /* IMG SORT SVG */
      arrow_down: require("@/assets/svgs/icons/sort/arrow_down.svg"),
      arrow_up: require("@/assets/svgs/icons/sort/arrow_up.svg"),
      arrow_null: require("@/assets/svgs/icons/sort/arrow.svg"),
    };
  },

  /* watch */
  watch: {
    isMobile: {
      deep: true,
      immediate: true,
      handler(cur) {
        if (cur) {
          this.chartConfig.is_show_bar = true;
          this.chartConfig.is_show_goal_line = false;
          this.chartConfig.is_show_register_line = false;
        } else {
          this.chartConfig.is_show_bar = true;
          this.chartConfig.is_show_goal_line = true;
          this.chartConfig.is_show_register_line = true;
        }
      },
    },
    campaignDropdown: {
      deep: true,
      handler(cur) {
        if (cur.length > 0 && this.campaignSelected === null) {
          this.campaignSelected = cur[0].campaignCode;
        }
      },
    },
    campaignSelected: {
      deep: true,
      handler(cur, old) {
        if (cur !== null && cur.length > 0) {
          this.campaignInfoForm.campaignCode = cur;
          this.campaignConditionForm.campaignCode = cur;
          this.dashboardSummaryForm.campaignCode = cur;
          this.dashboardChartForm.campaignCode = cur;
          this.dashboardInfoForm.campaignCode = cur;
          this.activityLogsForm.campaignCode = cur;
        }
      },
    },
    campaignInfoForm: {
      deep: true,
      handler(cur, old) {
        if (this.campaignInfoDebounceTime) clearInterval(this.campaignInfoDebounceTime);

        this.campaignInfoDebounceTime = setTimeout(() => {
          const getCampaignInfoPayload: __GetCampaignInfo = {
            ...cur,
          };
          this.$store.dispatch("getCampaignInfo", getCampaignInfoPayload);
        }, this.debounceTime);
      },
    },
    campaignConditionForm: {
      deep: true,
      handler(cur, old) {
        if (this.campaignConditionDebounceTime) clearInterval(this.campaignConditionDebounceTime);

        this.campaignConditionDebounceTime = setTimeout(() => {
          const getCampaignConditionPayload: __GetCampaignCondition = {
            ...cur,
          };
          this.$store.dispatch("getCampaignCondition", getCampaignConditionPayload);
        }, this.debounceTime);
      },
    },
    dashboardSummaryForm: {
      deep: true,
      handler(cur, old) {
        if (this.dashboardSummaryDebounceTime) clearInterval(this.dashboardSummaryDebounceTime);

        this.dashboardSummaryDebounceTime = setTimeout(() => {
          const getDashboardSummaryPayload: __GetDashboardSummary = {
            ...cur,
            startDate: this.$dayjs(cur.startDate).format("YYYY-MM-DD"),
            endDate: this.$dayjs(cur.endDate).format("YYYY-MM-DD"),
          };
          this.$store.dispatch("getDashboardSummary", getDashboardSummaryPayload);
        }, this.debounceTime);
      },
    },
    dashboardChartForm: {
      deep: true,
      handler(cur, old) {
        if (this.dashboardChartDebounceTime) clearInterval(this.dashboardChartDebounceTime);

        this.dashboardChartDebounceTime = setTimeout(() => {
          const startDate = this.$dayjs(cur.startDate).format("YYYY-MM-DD");
          const endDate = this.$dayjs(cur.endDate).format("YYYY-MM-DD");
          const differentDate = Math.abs(this.$dayjs(startDate).diff(this.$dayjs(endDate), "day"));
          let dateSort: "DAY" | "MONTH" | "YEAR" = "DAY";
          if (this.isMobile) {
            if (differentDate <= 7) {
              dateSort = "DAY";
              this.chartConfig.date_format = "YYYY-MM-DD";
            } else if (differentDate > 7 && differentDate <= 210) {
              dateSort = "MONTH";
              this.chartConfig.date_format = "YYYY-MM";
            } else {
              dateSort = "YEAR";
              this.chartConfig.date_format = "YYYY";
            }
          } else {
            if (differentDate <= 31) {
              dateSort = "DAY";
              this.chartConfig.date_format = "YYYY-MM-DD";
            } else if (differentDate > 31 && differentDate <= 365) {
              dateSort = "MONTH";
              this.chartConfig.date_format = "YYYY-MM";
            } else {
              dateSort = "YEAR";
              this.chartConfig.date_format = "YYYY";
            }
          }

          /* Date Sort For Excel Export */
          this.date = {
            ...this.date,
            dateSort: dateSort,
          };

          const getDashboardChartPayload: __GetDashboardChart = {
            ...cur,
            startDate: startDate,
            endDate: endDate,
            dateSort: dateSort,
          };
          this.$store.dispatch("getDashboardChart", getDashboardChartPayload);
        }, this.debounceTime);
      },
    },
    dashboardInfoForm: {
      deep: true,
      handler(cur, old) {
        if (this.dashboardInfoDebounceTime) clearInterval(this.dashboardInfoDebounceTime);

        this.dashboardInfoDebounceTime = setTimeout(() => {
          const getDashboardInfoPayload: __GetDashboardInfo = {
            ...cur,
            startDate: this.$dayjs(cur.startDate).format("YYYY-MM-DD"),
            endDate: this.$dayjs(cur.endDate).format("YYYY-MM-DD"),
          };
          this.$store.dispatch("getDashboardInfo", getDashboardInfoPayload);
        }, this.debounceTime);
      },
    },
    activityLogsForm: {
      deep: true,
      handler(cur, old) {
        if (this.activityLogsDebounceTime) clearInterval(this.activityLogsDebounceTime);

        if (this.dateFilter.startDate !== cur.startDate || this.dateFilter.endDate !== cur.endDate) {
          this.activityLogsForm.startDate = this.$dayjs(this.dateFilter.startDate).format("YYYY-MM-DD");
          this.activityLogsForm.endDate = this.$dayjs(this.dateFilter.endDate).format("YYYY-MM-DD");
        }
        this.activityLogsDebounceTime = setTimeout(() => {
          const getActivityLogsPayload: __GetActivityLogs = {
            ...cur,
            startDate: this.$dayjs(cur.startDate).format("YYYY-MM-DD"),
            endDate: this.$dayjs(cur.endDate).format("YYYY-MM-DD"),
          };
          this.$store.dispatch("getActivityLogs", getActivityLogsPayload);
        }, this.debounceTime);
      },
    },
    dateFilter: {
      deep: true,
      handler(cur, old) {
        let { startDate, endDate } = cur;
        const startDateJS = this.$dayjs(startDate);
        const endDateJS = this.$dayjs(endDate);

        if (endDateJS.isBefore(startDateJS)) {
          this.dateFilter.startDate = endDate;
          return;
        }
        if (startDateJS.isAfter(endDateJS)) {
          this.dateFilter.endDate = startDate;
          return;
        }

        // update date and fetch new data of all tab
        const keys: string[] = [
          "dashboardSummaryForm",
          "dashboardChartForm",
          "dashboardInfoForm",
          "activityLogsForm",
        ];
        
        keys.forEach((value) => {
          this[value].startDate = this.$dayjs(cur.startDate).format("YYYY-MM-DD");
          this[value].endDate = this.$dayjs(cur.endDate).format("YYYY-MM-DD");
        });
      },
    },
    paginationLengthDashboardInfoForm(cur) {
      this.dashboardInfoForm.pagination = {
        ...this.dashboardInfoForm.pagination,
        page: 1,
      };
    },
    paginationLengthActivityLogsForm(cur) {
      this.activityLogsForm.pagination = {
        ...this.activityLogsForm.pagination,
        page: 1,
      };
    },
    showOnlyDateWithData: {
      handler(cur, old) {
        if (cur !== old) {
          // fetch new dashboard data
          this.dashboardSummaryForm.showOnlyDateWithData = cur;
          this.dashboardChartForm.showOnlyDateWithData = cur;
          this.dashboardInfoForm.showOnlyDateWithData = cur;
        }
      },
    },
    /* Chart */
    dashboardChart: {
      deep: true,
      handler(cur: IDashboardChart[]) {
        this.chartConfig.data = cur.map((value) => ({
          conversion: value.conversion,
          goal_achieved: value.goalAchieved,
          label: this.$dayjs(value.date).format("YYYY-MM-DD"),
          register: value.register,
          reward: value.reward,
        }));
        this.DoSetData(this.chartConfig.data);
      },
    },
  },

  /* methods */
  methods: {
    addComma: ADD_COMMA,
    sortDateTime() {
      this.oppositeSortDictionary("activityLogsForm", "DateTime");
    },
    sortDate() {
      this.oppositeSortDictionary("dashboardInfoForm", "Date");
    },
    sortRegister() {
      this.oppositeSortDictionary("dashboardInfoForm", "Register");
    },
    sortGoalAchieved() {
      this.oppositeSortDictionary("dashboardInfoForm", "GoalAchieved");
    },
    sortReward() {
      this.oppositeSortDictionary("dashboardInfoForm", "Reward");
    },
    oppositeSortDictionary(
      key: "dashboardInfoForm" | "activityLogsForm",
      sortBy: null | "DateTime" | "Date" | "Register" | "GoalAchieved" | "Reward"
    ) {
      const sortByNow = this[key].sortBy;
      const sortDictionaryNow = this[key].sortDictionary;

      if (sortBy !== sortByNow) {
        this[key].sortBy = sortBy;
        this[key].sortDictionary = "DESC";
        return;
      }

      if (sortDictionaryNow) {
        if (sortDictionaryNow === "DESC") {
          this[key].sortDictionary = "ASC";
        } else {
          this[key].sortBy = null;
          this[key].sortDictionary = null;
        }
      } else {
        this[key].sortDictionary = "DESC";
      }
    },
    /* Pagination length */
    changeNumberPerPage(e) {
      if (this.tabActive === 1)
        this.dashboardInfoForm.pagination = {
          length: 10,
          page: Number(e),
        };
      else
        this.activityLogsForm.pagination = {
          length: 10,
          page: Number(e),
        };
    },
    /*  Pagination page  */
    changeLength(e) {
      if (this.tabActive === 1)
        this.dashboardInfoForm.pagination = {
          length: Number(e),
          page: 1,
        };
      else
        this.activityLogsForm.pagination = {
          length: Number(e),
          page: 1,
        };
    },
    openLinkInNewTab() {
      const baseUrl = process.env.VUE_APP_BASE_URL_PEAK;
      window.open(`${baseUrl}/register/partner?c=${this.campaignSelected}`, "_blank")?.focus();
    },
    async copyToClipboard() {
      const baseUrl = process.env.VUE_APP_BASE_URL_PEAK;
      await navigator.clipboard.writeText(`${baseUrl}/register/partner?c=${this.campaignSelected}`);
      this.snackbar = true;
    },
    pageNow(value) {
      if (this.tabActive === 1) {
        if (value > this.paginationDashboardInfo.totalPages || value < 1) {
          this.dashboardInfoForm.pagination.page = 1;
          return;
        }
        this.dashboardInfoForm.pagination.page = value;
      } else {
        if (value > this.paginationActivityLogs.totalPages || value < 1) {
          this.activityLogsForm.pagination.page = 1;
          return;
        }
        this.activityLogsForm.pagination.page = value;
      }
    },
    /* Check img to show */
    getCampaignStatusIcon(status) {
      return status === true ? require("@/assets/svgs/icons/active.svg") : require("@/assets/svgs/icons/inactive.svg");
    },
    setChartConfig(property, v) {
      this.chartConfig = {
        ...this.chartConfig,
        is_show_bar: false,
        is_show_goal_line: false,
        is_show_register_line: false,
        [property]: v,
      };
    },

    /* Search From Date to Date */
    onSearch(e) {
      this.dateFilter = {
        ...this.dateFilter,
        ...e,
      };
    },

    /* Export Excel */
    DoExportExcelResult() {
      if (this.tabActive === 1) {
        const getDashboardPayload = {
          campaignCode: this.campaignSelected,
          ...this.dateFilter,
          dateSort: this.date.dateSort,
          showOnlyDateWithData: this.showOnlyDateWithData,
        };
        this.$store.dispatch("getExcelDashboard", getDashboardPayload);
      } else {
        const getActivityLogsPayload = {
          campaignCode: this.campaignSelected,
          ...this.dateFilter,
        };
        this.$store.dispatch("getExcelActivityLog", getActivityLogsPayload);
      }
    },
    DoSetData(e) {
      this.isWaitChart = false;
      /* reward */
      const reward = e.map((item) => (item.reward > 0 ? item.reward : 0.01));
      this.series[0].data = reward as number[];
      /* register */
      const register = e.map((item) => (item.register > 0 ? item.register : 0.01));
      this.series[1].data = register as number[];
      /* goal */
      const conversion = e.map((item) => (item.conversion > 0 ? item.conversion : 0.01));
      this.series[2].data = conversion as number[];
      /* label */
      const labels = e.map((item) => item.label);
      const _labels = labels.reverse();
      this.chartOptions.xaxis.categories = _labels;

      setTimeout(() => {
        this.isWaitChart = true;
      });
    },
    numberHeader(e) {
      const headerDashboardInfo =
        e + (this.dashboardInfoForm.pagination.page - 1) * this.dashboardInfoForm.pagination.length;
      const headerActivityLogs =
        e + (this.activityLogsForm.pagination.page - 1) * this.activityLogsForm.pagination.length;

      if (this.tabActive === 1) return headerDashboardInfo;
      if (this.tabActive === 2) return headerActivityLogs;
    },
    onChangeFormatDateTime(e) {
      const _index = this.$dayjs(e).format("YYYY/MM/DD HH:mm:ss");
      return _index;
    },
  },

  /* computed */
  computed: {
    /* ---------------------------------------------------------- */
    /* Logic Table Dashboard */
    isNoDataDashboard(): boolean {
      return !this.isErrorDashboardInfo && !this.isLoadingDashboardInfo && this.dashboardInfo.length <= 0;
    },
    isLoadingDashboard(): boolean {
      return this.isLoadingDashboardInfo && !this.isErrorDashboardInfo;
    },
    isErrorFoundDashboard(): boolean {
      return !this.isLoadingDashboardInfo && this.isErrorDashboardInfo;
    },
    /* ---------------------------------------------------------- */
    /* Logic Table ActivityLogs */
    isNoDataActivityLogs(): boolean {
      return !this.isErrorActivityLogs && !this.isLoadingActivityLogs && this.activityLogs.length <= 0;
    },
    isLoadingActivityLog(): boolean {
      return this.isLoadingActivityLogs && !this.isErrorActivityLogs;
    },
    isErrorFoundActivityLogs(): boolean {
      return !this.isLoadingActivityLogs && this.isErrorActivityLogs;
    },

    activityLogs(): IActivityLog[] {
      return this.$store.getters["activityLogs"];
    },
    isLoadingActivityLogs(): boolean {
      return this.$store.getters["isLoadingActivityLogs"];
    },
    isErrorActivityLogs(): boolean {
      return this.$store.getters["isErrorActivityLogs"];
    },
    errorActivityLogs(): string | null {
      return this.$store.getters["errorActivityLogs"];
    },
    paginationActivityLogs(): IPagination {
      return this.$store.getters["paginationActivityLogs"];
    },
    paginationLengthActivityLogsInfoForm(): number {
      return this.activityLogsForm.pagination.length;
    },

    /* ---------------------------------------------------------- */
    user(): IUser | null {
      return this.$store.getters["auth/user"];
    },
    isNoCampaign(): boolean {
      return !this.campaignSelected || this.isLoadingCampaignDropdown || this.isErrorCampaignDropdown;
    },
    campaignDropdown(): ICampaignDropdown[] {
      return this.$store.getters["campaignDropdown"];
    },
    isLoadingCampaignDropdown(): boolean {
      return this.$store.getters["isLoadingCampaignDropdown"];
    },
    isErrorCampaignDropdown(): boolean {
      return this.$store.getters["isErrorCampaignDropdown"];
    },
    errorCampaignDropdown(): string | null {
      return this.$store.getters["errorCampaignDropdown"];
    },
    isNoCampaigns(): boolean {
      return this.$store.getters["isNoCampaigns"];
    },
    campaignInfo(): ICampaignInfo | null {
      return this.$store.getters["campaignInfo"];
    },
    isLoadingCampaignInfo(): boolean {
      return this.$store.getters["isLoadingCampaignInfo"];
    },
    isErrorCampaignInfo(): boolean {
      return this.$store.getters["isErrorCampaignInfo"];
    },
    errorCampaignInfo(): string | null {
      return this.$store.getters["errorCampaignInfo"];
    },
    isNoCampaignInfo(): boolean {
      return this.$store.getters["isNoCampaignInfo"];
    },
    isCampaignInfoEmpty(): boolean {
      return this.isNoCampaignInfo || this.isLoadingCampaignInfo || this.isErrorCampaignInfo;
    },
    campaignCondition(): TGoalCondition | null {
      return this.$store.getters["campaignCondition"];
    },
    isLoadingCampaignCondition(): boolean {
      return this.$store.getters["isLoadingCampaignCondition"];
    },
    isErrorCampaignCondition(): boolean {
      return this.$store.getters["isErrorCampaignCondition"];
    },
    errorCampaignCondition(): string | null {
      return this.$store.getters["errorCampaignCondition"];
    },
    isNoCampaignCondition(): boolean {
      return this.$store.getters["isNoCampaignCondition"];
    },
    isCampaignConditionEmpty(): boolean {
      return this.isNoCampaignCondition || this.isLoadingCampaignCondition || this.isErrorCampaignCondition;
    },
    dashboardSummary(): IDashboardSummary | null {
      return this.$store.getters["dashboardSummary"];
    },
    isLoadingDashboardSummary(): boolean {
      return this.$store.getters["isLoadingDashboardSummary"];
    },
    isErrorDashboardSummary(): boolean {
      return this.$store.getters["isErrorDashboardSummary"];
    },
    errorDashboardSummary(): string | null {
      return this.$store.getters["errorDashboardSummary"];
    },
    isNoDashboardSummary(): boolean {
      return this.$store.getters["isNoDashboardSummary"];
    },
    isDashboardSummaryEmpty(): boolean {
      return this.isNoDashboardSummary || this.isLoadingDashboardSummary || this.isErrorDashboardSummary;
    },
    dashboardChart(): IDashboardChart[] {
      return this.$store.getters["dashboardChart"];
    },
    isLoadingDashboardChart(): boolean {
      return this.$store.getters["isLoadingDashboardChart"];
    },
    isErrorDashboardChart(): boolean {
      return this.$store.getters["isErrorDashboardChart"];
    },
    errorDashboardChart(): string | null {
      return this.$store.getters["errorDashboardChart"];
    },
    isNoDashboardChart(): boolean {
      return this.$store.getters["isNoDashboardChart"];
    },
    dashboardInfo(): IDashboardInfo[] {
      return this.$store.getters["dashboardInfo"];
    },
    isLoadingDashboardInfo(): boolean {
      return this.$store.getters["isLoadingDashboardInfo"];
    },
    isErrorDashboardInfo(): boolean {
      return this.$store.getters["isErrorDashboardInfo"];
    },
    errorDashboardInfo(): string | null {
      return this.$store.getters["errorDashboardInfo"];
    },
    paginationDashboardInfo(): IPagination {
      return this.$store.getters["paginationDashboardInfo"];
    },
    paginationLengthDashboardInfoForm(): number {
      return this.dashboardInfoForm.pagination.length;
    },
    getWindowsSize() {
      return this.$store.getters["windowsSize"];
    },
    isRegisterMode(): boolean {
      return this.chartConfig.is_show_register_line;
    },
    isGoalAchievedMode(): boolean {
      return this.chartConfig.is_show_goal_line;
    },
    isRewardMode(): boolean {
      return this.chartConfig.is_show_bar;
    },
    getExcelResult(): string {
      return this.$store.getters["resultExcel"];
    },
    sortIconStartDate() {
      const sortBy = this.dashboardInfoForm.sortBy;
      const dictionary = this.dashboardInfoForm.sortDictionary;

      if (sortBy === "Date") {
        if (dictionary === "ASC") return "arrow_up";
        else return "arrow_down";
      }
      return "arrow_null";
    },
    sortIconRegister() {
      const sortBy = this.dashboardInfoForm.sortBy;
      const dictionary = this.dashboardInfoForm.sortDictionary;

      if (sortBy === "Register") {
        if (dictionary === "ASC") return "arrow_up";
        else return "arrow_down";
      }
      return "arrow_null";
    },
    sortIconGoalAchieved() {
      const sortBy = this.dashboardInfoForm.sortBy;
      const dictionary = this.dashboardInfoForm.sortDictionary;

      if (sortBy === "GoalAchieved") {
        if (dictionary === "ASC") return "arrow_up";
        else return "arrow_down";
      }
      return "arrow_null";
    },
    sortIconReward() {
      const sortBy = this.dashboardInfoForm.sortBy;
      const dictionary = this.dashboardInfoForm.sortDictionary;

      if (sortBy === "Reward") {
        if (dictionary === "ASC") return "arrow_up";
        else return "arrow_down";
      }
      return "arrow_null";
    },
    sortIconDateTime() {
      const sortBy = this.activityLogsForm.sortBy;
      const dictionary = this.activityLogsForm.sortDictionary;

      if (sortBy === "DateTime") {
        if (dictionary === "ASC") return "arrow_up";
        else return "arrow_down";
      }
      return "arrow_null";
    },
  },

  /* unmounted */
  unmounted() {
    /* Clear Debounce */
    clearTimeout(this.campaignInfoDebounceTime);
    clearTimeout(this.campaignConditionDebounceTime);
    clearTimeout(this.dashboardSummaryDebounceTime);
    clearTimeout(this.dashboardChartDebounceTime);
    clearTimeout(this.dashboardInfoDebounceTime);
    clearTimeout(this.activityLogsDebounceTime);

    /* Reset Campaign */
    this.$store.dispatch("resetCampaign");
  },

  /* mounted */
  mounted() {
    /* Get Campaign Code */
    this.campaignSelected = this.$store.getters["campaignCodeSelected"];

    /* Get Partner Code */
    const partnerCode = this.user?.partnerCode || "";
    const search = null;

    /* Get Campaign Dropdown */
    const payload: __GetCampaignDropdown = { partnerCode, search };
    this.$store.dispatch("getCampaignDropdown", payload);
    setTimeout(() => {
      if (this.campaignDropdown.length === 0) {
        router.push({
          path: "/partners/campaigns",
          query: { warning: "not-found-campaign" },
        });
      }
    }, 500);

    /* Change Mode to Mobile */
    this.getWindowsSize["x"] < 600 ? (this.isMobile = true) : (this.isMobile = false);
  },
});
